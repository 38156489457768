/* insurance LANDING*/
.public-insurance-header {
  color: #ffffff;
  padding: 80px 0 60px;
}

.public-insurance-header h1 {
  font-size: 40px;
  margin-bottom: 35px;
}
.insurance-cards-image {
  position: absolute;
  width: 90%;
  max-width: 500px;
  bottom: -50px;
  right: 0;
  z-index: 4;
}
@media screen and (min-width: 480px) {
  .public-insurance-header {
    padding: 125px 0 60px;
  }
  .public-insurance-header h1 {
    font-size: calc(30px + 40 * ((100vw - 320px) / 680));
  }
  .public-insurance-header h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1000px) {
  .public-insurance-header h1 {
    font-size: 70px;
  }
  .public-insurance-header h2 {
    font-size: 30px;
    margin-top: 40px;
  }
}
.insurance-disclaimer {
  font-size: 16;
  opacity: 0.8;
}
.insurance-logo {
  height: 12px;
  transform: translateY(2px);
  margin-right: 3px;
}
.insurance-faq {
  padding: 50px 0 80px;
  background-color: #fffff7;
  position: relative;
}
.svg-curve {
  height: 60px;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 3;
}
.svg-curve svg {
  position: absolute;
  bottom: 0;
  left: 0;
}
.section-title {
  font-size: 50px;
  margin-bottom: 50px;
}
.insurance-faq a {
  font-size: 20px;
  display: block;
  margin-bottom: 40px;
}
