/* Features */
.mobile-features {
  padding: 100px 0 100px;
}
.mobile-features .icon {
  margin-top: 40px;
}
.mobile-features .feature-title {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
}
