/* Header */
.header {
  color: #ffffff;
  padding: 60px 0 20px;
}
.header h1 {
  font-size: 40px;
  margin-bottom: 35px;
}
.disclaimer {
  font-size: 16;
  opacity: 0.8;
}
@media screen and (min-width: 480px) {
  .header h1 {
    font-size: calc(30px + 40 * ((100vw - 320px) / 680));
  }
  .header h2 {
    font-size: 24px;
  }
  .header {
    padding: 125px 0 100px;
  }
}
@media screen and (min-width: 1000px) {
  .header h1 {
    font-size: 70px;
  }
  .header h2 {
    font-size: 30px;
    margin-top: 40px;
  }
}
