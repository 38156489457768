.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;
  min-height: 500px;
}

.customerService {
  .container {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .bigTitle {
    font-size: 54px;
    font-style: italic;
    font-weight: 300;
    margin: 0 0 40px;
    line-height: 1.1;
  }
  .title {
    font-size: 30px;
	font-style: italic;
    font-weight: 300;
    margin: 40px 0 0;
    line-height: 1.2;
  }
}
