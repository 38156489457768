/* Header */
.header {
  color: #ffffff;
  padding: 60px 0 20px;
}
.header h1 {
  font-size: 40px;
  margin-bottom: 5px;
}
.disclaimer {
  font-size: 16;
  opacity: 0.7;
}
@media screen and (min-width: 480px) {
  .header h1 {
    font-size: calc(30px + 40 * ((100vw - 320px) / 680));
  }
  .header h2 {
    font-size: 24px;
  }
  .header {
    padding: 60px 0 10px;
  }
}
@media screen and (min-width: 1000px) {
  .header h1 {
    font-size: 70px;
  }
  .header h2 {
    font-size: 30px;
    margin-top: 40px;
  }
  .article {
    padding: 50px 100px 10px 100px !important;
  }
}

.information {
  padding: 100px 0;
  background: #3f2a56;
}
/* Product Cards */

.productSection .ui.fluid.card {
  margin: auto;
}
.product-card-disabled {
  opacity: 0.8;
}
.product-card .ui.grid {
  margin-left: -3px;
  margin-right: -3px;
}
.product-card .ui.grid > .row > .column {
  padding-left: 3px;
  padding-right: 3px;
}
.productSection {
  padding: 60px 0 30px;
  background-color: #3f2a56;
}
.productSection .ui.grid > .row > .column {
  margin-bottom: 2em;
}
.product-card .product-card-title {
  font-size: 30px;
  font-style: normal;
  color: #4b4a4d;
}
.product-card-info {
  color: #696969;
  padding: 10px;
  font-size: 15px;
  border-radius: 6px;
  background-color: rgba(242, 220, 233, 0.25);
  min-height: 85px;
}
.product-card-info strong {
  color: #4b4a4d;
  font-size: 22px;
}
.product-card .product-card-price {
  color: #4b4a4d;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  margin: 0 !important;
}
.product-card .product-card-price-detail {
  color: #8e8e8e;
  font-size: 16px;
  margin-bottom: 20px !important;
}
.cardButton {
  color: #ffffff;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 15px;
  background: linear-gradient(225deg, #fb637e 0%, #ff6900 100%);
}
.product-card .link-more-info {
  color: #615e9b;
  font-size: 14px;
}
.greendot {
  height: 10px;
  width: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  display: inline-block;
}
.yellowdot {
  height: 10px;
  width: 10px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}
.link-to-product {
  color: #615e9b;
  font-size: 14px;
}
