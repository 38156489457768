//Below 768px
@mixin xs {
  @media (max-width: 767px) { @content; }
}
//Above 768px and below 992px
@mixin sm {
  @media (min-width: 768px) and (max-width: 991px) { @content; }
}

//Above 992px
@mixin md {
  @media (min-width: 992px) and (max-width: 1399px) { @content; }
}

//Above 992px and below 1200
@mixin mdx {
  @media (min-width: 992px) and (max-width: 1199px) { @content; }
}

//Above 992px and below 1200
@mixin mdxless {
  @media (max-width: 1199px) { @content; }
}

//Above 1400px
@mixin lg {
  @media (min-width: 1400px) and (max-width: 1919px) { @content; }
}

//Above 1920px
@mixin xlg {
  @media (min-width: 1920px) { @content; }
}
